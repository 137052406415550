import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'
import { MAX_WORD_LENGTH, MAX_CHALLENGES } from '../../constants/settings'
import {
  CogIcon,
} from '@heroicons/react/outline'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Como Jogar" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
       Adivinhe a palavra do dia em {MAX_CHALLENGES} tentativas. A palavra e o chute podem possuir qualquer tamanho até o máximo de {MAX_WORD_LENGTH} letras (incluindo espaços). 
      </p>
      <br/>
      <p className="text-sm text-gray-500 dark:text-gray-300">
       Após a cada chute, a cor das letras irão mudar para indicar o quão perto você está.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="M"
          status="correct"
        />
        <Cell value="O" />
        <Cell value="S" />
        <Cell value="A" />
        <Cell value="I" />
        <Cell value="C" />
        <Cell value="O" />
        <Cell value=" " />
        <Cell value="F" />
        <Cell value="L" />
        <Cell value="U" />
        <Cell value="I" />
        <Cell value="D" />
        <Cell value="O" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        A letra M faz parte da palavra e está na posição correta.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="M" />
        <Cell value="I" />
        <Cell value="T" />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="O"
          status="present"
        />
        <Cell value="C" />
        <Cell value="O" />
        <Cell value="N" />
        <Cell value="D" />
        <Cell value="R" />
        <Cell value="I" />
        <Cell value="A" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        A letra O faz parte da palavra, mas está na posição errada.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="C" />
        <Cell value="A" />
        <Cell isRevealing={true} isCompleted={true} value="R" status="absent"/>
        <Cell value="R" />
        <Cell value="A" />
        <Cell value="M" />
        <Cell value="U" />
        <Cell value="J" />
        <Cell value="O" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        A letra R não faz parte da palavra em nenhum lugar.
      </p>
      <br/>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Não é necessário colocar acentos nos chutes.
      </p>
      <br/>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Em configurações (<CogIcon style={{display:'inline'}} className="h-6 w-6 cursor-pointer dark:stroke-white"/>) é possível alterar opções do jogo.
      </p>
    </BaseModal>
  )
}
