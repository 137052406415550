import { WORDS } from '../constants/wordlist'
import { VALID_GUESSES } from '../constants/validGuesses'
import { WRONG_SPOT_MESSAGE, NOT_CONTAINED_MESSAGE } from '../constants/strings'
import { getGuessStatuses } from './statuses'
import { default as GraphemeSplitter } from 'grapheme-splitter'

const convertedWords = WORDS.map(item => item.word);
const wordTips = WORDS.map(item => item.tip);
//const convertedValidGuess = VALID_GUESSES.concat(convertedWords);

const isWordInWord = (word: string) => {
  if (convertedWords.some(function(element, i) {
    //console.log("IS WORD IN WORD");
    //console.log(cleanUpSpecialChars(localeAwareUpperCase(word)));
    //console.log(cleanUpSpecialChars(localeAwareUpperCase(element)));

    if (cleanUpSpecialChars(localeAwareUpperCase(word)) === cleanUpSpecialChars(localeAwareUpperCase(element))) {
      //console.log("#################ACHOU################");
      return true;
    }
      return false;
  })) {
    return true;
  }
  return false;
}

export const cleanUpSpecialChars = (str: string) => {
  return str
    .replace(/[ÀÁÂÃ]/g,"A")
    .replace(/[ÈÉÊË]/g,"E")
    .replace(/[ÌÍÎ]/g,"I")
    .replace(/[ÒÓÔÕÖ]/g,"O")
    .replace(/[ÙÚÛÜ]/g,"U")
    .replace(/[Ç]/g,"C")
    
}
/*
    .replace(/[àáâãäå]/g,"a")
    .replace(/[èéêë]/g,"e")
    .replace(/[ìíîï]/g,"i")
    .replace(/[òóôõö]/g,"o")
    .replace(/[ùúûü]/g,"u")
    .replace(/[ç]/g,"c")
    */


export const isWordInWordList = (word: string) => {
  return (
    isWordInWord(cleanUpSpecialChars(localeAwareUpperCase(word))) ||
    VALID_GUESSES.includes(cleanUpSpecialChars(localeAwareUpperCase(word)))
  )
}

export const isWinningWord = (word: string) => {
  //console.log("WINING WORD");
  //console.log(cleanUpSpecialChars(localeAwareLowerCase(solution)));
  //console.log(cleanUpSpecialChars(localeAwareLowerCase(word)))

  return cleanUpSpecialChars(localeAwareUpperCase(solution)) === cleanUpSpecialChars(localeAwareUpperCase(word))
}

// build a set of previously revealed letters - present and correct
// guess must use correct letters in that space and any other revealed letters
// also check if all revealed instances of a letter are used (i.e. two C's)
export const findFirstUnusedReveal = (word: string, guesses: string[]) => {
  if (guesses.length === 0) {
    return false
  }

  const lettersLeftArray = new Array<string>()
  const guess = guesses[guesses.length - 1]
  const statuses = getGuessStatuses(solution, guess)
  const splitWord = unicodeSplit(word)
  const splitGuess = unicodeSplit(guess)

  for (let i = 0; i < splitGuess.length; i++) {
    if (statuses[i] === 'correct' || statuses[i] === 'present') {
      lettersLeftArray.push(splitGuess[i])
    }
    if (statuses[i] === 'correct' && splitWord[i] !== splitGuess[i]) {
      return WRONG_SPOT_MESSAGE(splitGuess[i], i + 1)
    }
  }

  // check for the first unused letter, taking duplicate letters
  // into account - see issue #198
  let n
  for (const letter of splitWord) {
    n = lettersLeftArray.indexOf(letter)
    if (n !== -1) {
      lettersLeftArray.splice(n, 1)
    }
  }

  if (lettersLeftArray.length > 0) {
    return NOT_CONTAINED_MESSAGE(lettersLeftArray[0])
  }
  return false
}

export const unicodeSplit = (word: string) => {
  return new GraphemeSplitter().splitGraphemes(word)
}

export const unicodeLength = (word: string) => {
  return unicodeSplit(word).length
}

export const localeAwareLowerCase = (text: string) => {
  return process.env.REACT_APP_LOCALE_STRING
    ? text.toLocaleLowerCase(process.env.REACT_APP_LOCALE_STRING)
    : text.toLowerCase()
}

export const localeAwareUpperCase = (text: string) => {
  return process.env.REACT_APP_LOCALE_STRING
    ? text.toLocaleUpperCase(process.env.REACT_APP_LOCALE_STRING)
    : text.toUpperCase()
}

export const getWordOfDay = () => {
  // January 1, 2022 Game Epoch
  const epoch = new Date(2022, 4, 17)
  const start = new Date(epoch)
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  let index = 0
  while (start < today) {
    index++
    start.setDate(start.getDate() + 1)
  }

  const nextDay = new Date(today)
  nextDay.setDate(today.getDate() + 1)

  return {
    //solution: localeAwareUpperCase(WORDS[index % WORDS.length]),
    solution: localeAwareUpperCase(convertedWords[index % convertedWords.length]),
    solutionIndex: index,
    tomorrow: nextDay.valueOf(),
    tip: wordTips[index % convertedWords.length],
  }
}

export const { solution, solutionIndex, tomorrow } = getWordOfDay()
