import {
  ChartBarIcon,
  CogIcon,
  InformationCircleIcon,
} from '@heroicons/react/outline'
import { GAME_TITLE } from '../../constants/strings'

import Quibio from '../quibio.jpg'

type Props = {
  setIsInfoModalOpen: (value: boolean) => void
  setIsStatsModalOpen: (value: boolean) => void
  setIsSettingsModalOpen: (value: boolean) => void
  isEasyMode?: boolean
}

const Quibioimage = () => {

  return (
    <a href="https://quibio.com.br/" target="_blank" rel="noreferrer"><img src={Quibio} alt="Avatar" style={{borderRadius: "50%", maxWidth:"40px", maxHeight:"40px", display:"inline"}}></img></a>
  )
}

export const Navbar = ({
  setIsInfoModalOpen,
  setIsStatsModalOpen,
  setIsSettingsModalOpen,
  isEasyMode,
}: Props) => {
  return (
    <div className="navbar">
      <div className="navbar-content px-5">
        <InformationCircleIcon
          className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white"
          onClick={() => setIsInfoModalOpen(true)}
        />
        
        <p className="text-xl ml-2.5 font-bold dark:text-white"><Quibioimage/>&nbsp;&nbsp;{GAME_TITLE}</p>
        <div className="right-icons">
          { isEasyMode && (<>
            <span className="px-2 py-0 rounded-full border border-green-700 text-green-700 bg-green-100 font-semibold text-sm flex align-center w-max">
              Easy
            </span>&nbsp;&nbsp;&nbsp;</>)
          }
          <ChartBarIcon
            className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
            onClick={() => setIsStatsModalOpen(true)}
          />
          <CogIcon
            className="h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsSettingsModalOpen(true)}
          />
        </div>
      </div>
      <hr></hr>
    </div>
  )
}
