export const VALID_GUESSES = [
  "ACTINIO",
  "PRATA",
  "ALUMINIO",
  "AMERICIO",
  "ARGONIO",
  "ARSENIO",
  "ASTATO",
  "OURO",
  "BORO",
  "BARIO",
  "BERILIO",
  "BOHRIO",
  "BISMUTO",
  "BERQUELIO",
  "BROMO",
  "CARBONO",
  "CALCIO",
  "CADMIO",
  "CERIO",
  "CALIFORNIO",
  "CLORO",
  "CURIO",
  "COPERNICIO",
  "COBALTO",
  "CROMO",
  "CESIO",
  "COBRE",
  "DUBNIO",
  "DARMSTACIO",
  "DISPROSIO",
  "ERBIO",
  "EINSTENIO",
  "EUROPIO",
  "FLUOR",
  "FERRO",
  "FLEROVIO",
  "FERMIO",
  "FRANCIO",
  "GALIO",
  "GADOLINIO",
  "GERMANIO",
  "HIDROGENIO",
  "HELIO",
  "HAFNIO",
  "MERCURIO",
  "HOLMIO",
  "HASSIO",
  "IODO",
  "INDIO",
  "IRIDIO",
  "POTASSIO",
  "CRIPTONIO",
  "LANTANIO",
  "LITIO",
  "LAURENCIO",
  "LUTECIO",
  "LIVERMORIO",
  "MOSCOVIO",
  "MENDELEVIO",
  "MAGNESIO",
  "MANGANES",
  "MOLIBDENIO",
  "MEITNERIO",
  "NITROGENIO",
  "SODIO",
  "NIOBIO",
  "NEODIMIO",
  "NEONIO",
  "NIPONIO",
  "NIQUEL",
  "NOBELIO",
  "NEPTUNIO",
  "OXIGENIO",
  "OGANESSON",
  "OSMIO",
  "FOSFORO",
  "PROTACTINIO",
  "CHUMBO",
  "PALADIO",
  "PROMECIO",
  "POLONIO",
  "PRASEODIMIO",
  "PLATINA",
  "PLUTONIO",
  "RADIO",
  "RUBIDIO",
  "RENIO",
  "RUTHERFORDIO",
  "ROENTGENIO",
  "RODIO",
  "RADONIO",
  "RUTENIO",
  "ENXOFRE",
  "ANTIMONIO",
  "ESCANDIO",
  "SELENIO",
  "SEABORGIO",
  "SILICIO",
  "SAMARIO",
  "ESTANHO",
  "ESTRONCIO",
  "TANTALO",
  "TERBIO",
  "TECNECIO",
  "TELURIO",
  "TORIO",
  "TITANIO",
  "TALIO",
  "TULIO",
  "TENESSO",
  "URANIO",
  "VANADIO",
  "TUNGSTENIO",
  "XENONION",
  "ITRIO",
  "ITERBIO",
  "ZINCO",
  "ZIRCONIO",
  "MEMBRANA PLASMATICA",
  "PAREDE CELULAR",
  "NUCLEO",
  "RIBOSSOMO",
  "PEROXISSOMO",
  "MITOCONDRIA",
  "LISOSSOMO",
  "CENTRIOLOS",
  "PLASTOS",
  "VACUOLO DE SUCO CELULAR",
  "GLIOXISSOMOS",
  "EUCARIONTE",
  "PROCARIONTE",
  "CITOPLASMA",
  "RETICULO ENDOPLASMATICO",
  "PLASTOS",
  "VACUOLOS",
  "CITOESQUELETO",
  "ORGANELAS",
  "GAMETA",
  "GAMETOGENESE",
  "GONIA",
  "HAPLOIDES",
  "ESPERMATOZOIDE",
  "EPIDIDIMO",
  "OVULO",
  "OVARIO",
  "MONOICOS",
  "AUTOFECUNDACAO",
  "DIOICA",
  "CARIOGAMIA",
  "ZIGOTO",
  "EMBRIAO",
  "BLASTOMEROS",
  "CLIVAGEM",
  "SEGMENTACAO",
  "BLASTOCELE",
  "TROFOBLASTOS",
  "EMBRIOBLASTOS",
  "PLACENTA",
  "GASTRULACAO",
  "ARQUENTERO",
  "DEUTEROSTOMIOS",
  "PROTOSTOMIOS",
  "ECTODERMA",
  "MESODERMA",
  "ENDODERMA",
  "ACELOMADOS",
  "PSEUDOCELOMADOS",
  "CELOMADOS",
  "ORGANOGENESE",
  "ACIDOFILO",
  "AFERENTE",
  "EFERENTE",
  "ACINO",
  "APICAL",
  "ARTEFATO DE TECNICA",
  "AVASCULAR",
  "BASAL",
  "BASOLATERAL",
  "BASOFILO",
  "DUTO EXCRETOR",
  "ENDOCITOSE",
  "ENDOTELIO",
  "ESTROMA",
  "EXOCITOSE",
  "GLANDULA MUCOSA",
  "GLANDULA SEROSA",
  "HILO",
  "LAMINA BASAL",
  "LAMINA PROPRIA",
  "LUMEN",
  "MEMBRANA BASAL",
  "MEMBRANA MUCOSA",
  "MEMBRANA SEROSA",
  "MESENTERIO",
  "MESOTELIO",
  "METACROMASIA",
  "MICROVILOSIDADE",
  "MUCOSA",
  "PARENQUIMA",
  "REGIAO CORTICAL",
  "REGIAO MEDULAR",
  "SEROSA",
  "POLARIDADE",
  "UNIDADE SECRETORA",
  "VISCERAS",
  "BIOMASSA",
  "BIOSFERA",
  "CADEIA ALIMENTAR",
  "CANIBALISMO",
  "COLONIA",
  "COMENSALISMO",
  "COMPONENTES ABIOTICOS",
  "COMPONENTES BIOTICOS",
  "COMUNIDADE",
  "COMUNIDADE CLIMAX",
  "COMUNIDADE PIONEIRA",
  "ECOSSISTEMA",
  "ESPECIE",
  "ESPECIE INVASORA",
  "HABITAT",
  "HERBIVORIA",
  "MUTUALISMO",
  "NICHO ECOLOGICO",
  "PARASITISMO",
  "POPULACAO",
  "RELACAO ECOLOGICA",
  "RELACAO INTERESPECIFICA",
  "RELACAO INTRAESPECIFICA",
  "SOCIEDADE",
  "SUCESSAO ECOLOGICA",
  "TEIA ALIMENTAR",
  "AEROBICA",
  "ANAEROBICA",
  "ANELIDEOS",
  "ARTROPODES",
  "ASSEXUADA",
  "CNIDARIOS",
  "EQUINODERMOS",
  "EUCARIONTES",
  "HETEROTROFICOS",
  "INVERTEBRADOS",
  "MOLUSCOS",
  "MULTICELULARES",
  "PLATELMINTOS",
  "PORIFEROS",
  "SESSIL",
  "SEXUADA",
  "SIMETRIA BILATERAL",
  "SIMETRIA RADIAL",
  "VERTEBRADOS",
  "ABAXIAL",
  "ACAULE",
  "ACICULADO",
  "ACTINOMORFA",
  "ACULEO",
  "ACUMINADO",
  "ADAXIAL",
  "ADNATO",
  "ADUBO VERDE",
  "ADVENTICIO",
  "AGUDO",
  "ALELOPATIA",
  "ALTERNA",
  "ALTERNA ESPIRALADA",
  "ANDROGINA",
  "ANTERA",
  "ANTESE",
  "AQUENIO",
  "AREOLADO",
  "AREOLAS",
  "ARILO",
  "ASSOCIACAO SIMBIOTICA",
  "ATENUADA",
  "AXILAR",
  "BACTERIAS FIXADORAS",
  "BAGA",
  "BAINHA",
  "BILABIADA",
  "BIVALVAR",
  "BRACTEA",
  "BRACTEOLA",
  "BULBILHOS",
  "BULBO",
  "BULBOSA",
  "CALCAR",
  "CALCARADA",
  "CALICE",
  "CALICULO",
  "CAMPANULADO",
  "CAPITULESCENCIA",
  "CAPITULIFORMES",
  "CAPITULO",
  "CAPSULA",
  "CARNOSO",
  "CARPELO",
  "CEROSA",
  "CESPITOSO",
  "CIMEIRA",
  "CIMOSA",
  "CINCINO",
  "CINORRODIO",
  "CLADODIO",
  "COLUNA ESTAMINAL",
  "COMPOSTA",
  "CONCOLOR",
  "CORDADA",
  "CORDIFORME",
  "CORIACEA",
  "CORIMBO",
  "COROLA",
  "COROLA LIGULADA",
  "CORONA",
  "CRENADA",
  "CULTIGENO",
  "CULTIVAR",
  "CUNEADA",
  "DANINHA",
  "DECIDUA",
  "DECOCCAO",
  "DECUMBENTE",
  "DECURRENTE",
  "DECUSSADA",
  "DEISCENCIA",
  "DEISCENTE",
  "DELTOIDE",
  "DENTEADA",
  "DEXTRORSO",
  "DIALIPETALA",
  "DIALISSEPALA",
  "DICLINA",
  "DICOTOMICA",
  "DIOICA",
  "DISCOLORES",
  "DISPERSAO",
  "DOSSEL",
  "DRUPA",
  "ELAIOFORO",
  "ELIPTICA",
  "ENDEMICA",
  "ENSIFORME",
  "ENTRENO",
  "EPICALICE",
  "EPIFILA",
  "EPIFITA",
  "EPITETO COLETIVO",
  "EPITETO ESPECIFICO",
  "EQUINOCARPO",
  "ESCABROSA",
  "ESCAMIFORME",
  "ESCANDENTE",
  "ESCAPO",
  "ESCORPIOIDE",
  "ESCUTELO",
  "ESPADICE",
  "ESPATA",
  "ESPIGA",
  "ESPINESCENTE",
  "ESPINHO",
  "ESPORA",
  "ESTAME",
  "ESTAMINODIO",
  "ESTIGMA",
  "ESTILETE",
  "ESTIPULAS",
  "ESTOLONIFERO",
  "ESTOLHO",
  "EXSICATA",
  "FALCADA",
  "FASCICULO",
  "FILARIA",
  "FILETE",
  "FILOCLADIO",
  "FILOGENIA",
  "FILOTAXIA",
  "FIMBRIADA",
  "FISTULOSA",
  "FLOR AXILAR",
  "FLORES DO DISCO",
  "FLORES DO RAIO",
  "FOLICULO",
  "FOLIOLO",
  "FORRAGEIRA",
  "FUSIFORME",
  "GAMOPETALA",
  "GAMOSSEPALO",
  "GAMOTEPALO",
  "GAVINHA",
  "GENERICO",
  "GENERO",
  "GEOTROPISMO",
  "GIBA",
  "GIBOSA",
  "GINECEU",
  "GLABRA",
  "GLAUCA",
  "GLOBOSA",
  "GLOMERULO",
  "GLOQUIDEO",
  "GRAMPIFORME",
  "GUIA DE NECTAR",
  "HABITO",
  "HEMIPARASITA",
  "HERMAFRODITA",
  "HIBRIDO",
  "HIPANTO",
  "HIPOCRATERIFORME",
  "HIRSUTA",
  "HOLOTIPO",
  "IMBRICADA",
  "IMPARIPINADA",
  "INDEISCENTE",
  "INFLORESCENCIA",
  "INFUNDIBULIFORME",
  "INVASORA",
  "INVOLUCRAIS",
  "LACINIO",
  "LANCEOLADAS",
  "LATESCENTE",
  "LATEX",
  "LECTOTIPO",
  "LEGUME",
  "LENHOSO",
  "LIGULA",
  "LIMBO",
  "LINEAR",
  "LINNAEUS",
  "LOBADA",
  "MEMBRANACEA",
  "MONOCLINA",
  "MONOICA",
  "NAVICULADA",
  "NECTAR",
  "NECTARIO",
  "NODULACAO",
  "NOMENCLATURA BINOMIAL",
  "NOMENCLATURA GENERICA",
  "OBLONGA",
  "OBOVADA",
  "OBTUSO",
  "OPOSTA",
  "OPOSTA CRUZADA",
  "ORBICULAR",
  "ORGAOS DE RESERVA",
  "OVALADO",
  "PALEACEA",
  "PANC",
  "PANICULA",
  "PAPIRACEA",
  "PAPILHO",
  "PARAFILETICO",
  "PAUCIFLORA",
  "PECIOLADA",
  "PECIOLO",
  "PEDICELO",
  "PEDUNCULO",
  "PELTADA",
  "PENTAMERA",
  "PERFILHO",
  "PERIANTO",
  "PERICARPELO",
  "PERICARPO",
  "PETALA",
  "PETALOIDE",
  "PILOSA",
  "PINADA",
  "PISTILO",
  "PIVOTANTE",
  "PIXIDIO",
  "PLAGIOTROPICO",
  "PLASTICIDADE FENOTIPICA",
  "PORCAO DISTAL",
  "PROCUMBENTE",
  "PROPAGULO",
  "PROSTRADO",
  "PSEUDOCAULE",
  "PSEUDOFRUTO",
  "PSEUDO UMBELA",
  "PUBESCENTE",
  "PURPURESCENTE",
  "QUIMIOTIPO",
  "RACEMO",
  "RACEMOSA",
  "RAFIDES",
  "RAQUE",
  "RECEPTACULO",
  "REFLEXA",
  "RETICULADO",
  "RITIDOMA",
  "RIZOFORO",
  "RIZOMA",
  "RIZOMATOSA",
  "ROSETA",
  "ROSTRADO",
  "ROTUNDA",
  "RUDERAL",
  "SARMENTO",
  "SCIENTIA AMABILIS",
  "SEMENTES F1",
  "SEMPRE VIVA",
  "SENESCENCIA",
  "SEPALAS",
  "SEPALOIDE",
  "SERICEO",
  "SERRILHADA",
  "SESSIL",
  "SILIQUA",
  "SINISTRORSO",
  "SINONIMIA",
  "SUBCORDADA",
  "SUBER",
  "SUBESPECIE",
  "SUBLENHOSA",
  "SUBULADA",
  "SUCULENTO",
  "SUFRUTICOSA",
  "TAXON",
  "TEPALA",
  "TETRAMERA",
  "TOMENTOSO",
  "TOPIARIA",
  "TREPADEIRA",
  "TRICOCA",
  "TRICOMAS",
  "TRIFOLIOLADA",
  "TRIMERAS",
  "TRULADA",
  "TRUNCADA",
  "TUBERCULO",
  "TUBEROSA",
  "TUBIFLORA",
  "TUBO ESTAMINAL",
  "TUBULOSA",
  "UMBELA",
  "VAGEM",
  "VARIEDADE",
  "VERTICILADA",
  "VERTICILASTRO",
  "VINACEO OU VINOSO",
  "VOLUVEL",
  "XEROFITA",
  "ZIGOMORFO",
  "ACULEO",
  "AERENQUIMA",
  "ALBURNO",
  "AMENDOA",
  "ANDROCEU",
  "ANEIS DE CRESCIMENTO",
  "ANTERA",
  "ANTERIDIO",
  "ANTEROZOIDE",
  "APICAL",
  "AQUENIO",
  "ARQUEGONIO",
  "BACULO",
  "BAGA",
  "BAINHA",
  "BOTAO VEGETATIVO",
  "BRACTEAS",
  "BROTO",
  "CALICE",
  "CALIPTRA",
  "CAMBIO VASCULAR",
  "CAPSULA",
  "CARIOPSE",
  "CARPELO",
  "CASCA",
  "CATAFILOS",
  "CAULE",
  "CAULICULO",
  "CAULOIDE",
  "CELULA",
  "CELULAS COMPANHEIRAS",
  "CERNE",
  "CILINDRO",
  "VASCULAR",
  "CLADOGRAMA",
  "CLORENQUIMA",
  "CLOROFILA",
  "CLOROPLASTO",
  "COIFA",
  "COLENQUIMA",
  "COLEOPTILO",
  "COLMO",
  "CONCEPTACUL",
  "COROLA",
  "CORTEX",
  "COTILEDONE",
  "CURVINERVEA",
  "CUTICULA",
  "CUTINA",
  "DEISCENTE",
  "DIAGRAMA",
  "FLORAL",
  "DICOGAMIA",
  "DIOICA",
  "DISPERSAO",
  "DRUPA",
  "EIXO EPICOTILO HIPOCOTILO",
  "EMBEBICAO",
  "EMBRIAO",
  "ENDOCARPO",
  "ENDOSPERMA",
  "ENTRENO",
  "EPICARPO",
  "EPICOTILO",
  "EPIDERME",
  "EPIFITA",
  "ESCLEREIDE",
  "ESCLERENQUIMA",
  "ESPINHO",
  "ESPORANGIO",
  "ESPORO",
  "ESPOROFITO",
  "ESTAME",
  "ESTIGMA",
  "ESTILETE",
  "ESTIPE",
  "ESTIPULA",
  "ESTOLHO",
  "ESTOMATO",
  "ESTROBILO",
  "FACE ABAXIAL",
  "FACE ADAXIAL",
  "FASCICULO",
  "FEIXES LIBEROLENHOSOS",
  "FELODERME",
  "FIBRA",
  "FILETE",
  "FILIDIO",
  "FLOEMA",
  "FLOR",
  "FLOR DIMERA",
  "FLOR PENTAMERA",
  "FLOR TETRAMERA",
  "FLOR TRIMERA",
  "FOLHA",
  "FOLHA FERTIL",
  "FOLHA JOVEM",
  "FOLIOLO",
  "FRAGMENTACAO",
  "FRUTA",
  "FRUTO",
  "FRUTOS CARNOSOS",
  "FRUTOS PARTENOCARPICOS",
  "FRUTOS SECOS",
  "GAMETA",
  "GAMETANGIO",
  "GAMETOFITO",
  "GAVINHA",
  "GEMA AXILAR",
  "GERMINACAO",
  "GERMINACAO EPIGEA",
  "GERMINACAO HIPOGEA",
  "GINECEU",
  "GRAO DE POLEN",
  "HERMAFRODITA",
  "HASTE",
  "HETEROCLAMIDEA",
  "HETEROSPORIA",
  "HIPOCOTILO",
  "HOMOCLAMIDEA",
  "HOMOSPORIA",
  "INDEISCENTE",
  "INDUSIO",
  "INFLORESCENCIA",
  "LEGUME",
  "LENHO",
  "LENTICELA",
  "LEUCOPLASTO",
  "LIBER",
  "LIMBO",
  "LOCULO",
  "LOMENTO",
  "MEGAESTROBILO",
  "MEGASPORANGIO",
  "MEGASPORO",
  "MEGASPOROCITO",
  "MEGASPOROFILO",
  "MERISTEMA",
  "MESOCARPO",
  "MESOFILO",
  "MICROESTROBILO",
  "MICROGAMETOFITO",
  "MICROGAMETOGENESE",
  "MICROPILA",
  "MICROSPORANGIO",
  "MICROSPORO",
  "MICROSPOROCITO",
  "MICROSPOROFILO",
  "MICROSPOROGENESE",
  "NECTARIO",
  "NERVURA FOLIAR",
  "NO",
  "NUCELO",
  "NUCLEO ESPERMATICO",
  "OOSFERA",
  "OSTIOLO",
  "OVARIO",
  "OVULO",
  "PARALELINERVEA",
  "PARENQUIMA",
  "PARENQUIMA AQUIFERO",
  "PARENQUIMA DE RESERVA",
  "PE",
  "PECIOLO",
  "PEDICELO",
  "PELO ABSORVENTE",
  "PENINERVEA",
  "PERIANTO",
  "PERICARPO",
  "PERICICLO",
  "PERIDERME",
  "PERIGONIO",
  "PETALA",
  "PLACENTA",
  "PLANTA",
  "PLANTULA",
  "PLASMODESMOS",
  "PLASTOS",
  "PLUMULA",
  "PNEUMATODIOS",
  "PROCAMBIO",
  "PROPAGACAO VEGETATIVA",
  "PROPAGULO",
  "PROTALO",
  "PROTODERME",
  "PROTONEMA",
  "PSEUDOFRUTO",
  "PSEUDOFRUTO AGREGADO",
  "PSEUDOFRUTO MULTIPLO",
  "PSEUDOFRUTO SIMPLES",
  "RADICULA",
  "RAIZ",
  "RAIZES ADVENTICIAS",
  "RAIZ FASCICULADA",
  "RAIZ PIVOTANTE",
  "RAMO",
  "RECEPTACULO FLORAL",
  "RITIDOMA",
  "RIZOIDE",
  "RIZOMA",
  "SACO EMBRIONARIO",
  "SAMARA",
  "SEIVA",
  "SEMENTE",
  "SEPALAS",
  "SORO",
  "SUBER",
  "TALO",
  "TECIDO DE SUSTENTACAO",
  "TEGUMENTO",
  "TEPALA",
  "TRAQUEIDE",
  "TRAQUEOFITA",
  "TRICOMA",
  "TRONCO",
  "TUBO POLINICO",
  "TURFA",
  "VASO LENHOSO",
  "VERTICILO FLORAL",
  "XEROFITAS",
  "XILEMA",
  "ZONA DE MATURACAO",
  "ZONA DE MULTIPLICACAO",
  "ZONA PILIFERA",
  "ZONA DE RAMIFICACAO",
  "ACAO ANTROPICA",
  "ALELOS",
  "AUTOGAMIA",
  "AUTOSSOMO",
  "BASE",
  "BLOCO HAPLOTIPICO",
  "CAMADA ESTRATIGRAFICA",
  "CARGA GENETICA",
  "CROMOSSOMO",
  "CROMOSSOMO Y",
  "DERIVA GENETICA",
  "DNA",
  "DNA MITOCONDRIAL",
  "DUPLICACAO GENICA",
  "EFEITO FUNDADOR",
  "EFEITO PLEIOTROPICO",
  "ENDOSSIMBIOSE",
  "EPIGENETICA",
  "ESTAMPAGEM GENOMICA",
  "ESTASE EVOLUCIONARIA",
  "ESTROMATOLITO",
  "EUCARIOTO",
  "EVOLUCIONISTAS",
  "EXAPTACAO",
  "FENOTIPO",
  "FIXISMO",
  "FREQUENCIA ALELICA",
  "GENOMA",
  "GRUPOS MONOFILETICOS",
  "HAPLOGRUPO",
  "HAPLOIDE",
  "HAPLOTIPO",
  "HOMOLOGIA",
  "HORMONIO MELANOTROPICO",
  "INDEL",
  "ISOENZIMAS",
  "LIGACAO DE TIOESTER",
  "LOCUS",
  "MARCADORES GENETICOS",
  "METILACAO DE CITOSINAS",
  "MICROSSATELITES",
  "MINISSATELITES",
  "MITOCONDRIA",
  "MUTACAO",
  "MUTACAO DE BASE",
  "NUCLEO",
  "NUCLEOTIDEO",
  "ORTOLOGIA",
  "PANSELECIONISTA",
  "PARALOGIA",
  "PCR",
  "PIRIMIDINA",
  "POLIMORFISMO",
  "POSICAO DE NUCLEOTIDEO",
  "PROCARIONTES",
  "PURINA",
  "QUIMIOSMOSE",
  "RADIACAO ADAPTATIVA",
  "RATITA",
  "REGIAO PROMOTORA",
  "RETROTRANSPOSON",
  "SELECAO SEXUAL",
  "SISTEMATICA FILOGENETICA",
  "SNP",
  "TRANSPOSON",
  "VICARIANCIA"
]
