import { Cell } from './Cell'
import { unicodeSplit } from '../../lib/words'
import { getWordOfDay } from '../../lib/words'
import { cleanUpSpecialChars } from '../../lib/words'



type Props = {
  rightLetters: string
  className: string
  guesses: string[]
  isEasyMode?: boolean
}

const WordOfDayRevelead = ({ rightLetters, className, guesses, isEasyMode }: Props) => {
  const wordOfDay = getWordOfDay().solution;
  const splitwordOfDay = unicodeSplit(wordOfDay);

  let isSolvedArray: Array<boolean> = [];
  for (let index = 0; index < wordOfDay.length; ++index) {
    isSolvedArray.push(false);
  }

  if (isEasyMode) { //If is easy mode, we need to check the guess letters for multiple aparison
    for (let index = 0; index < guesses.length; ++index) {
      for (let charindex = 0; charindex < guesses[index].length; ++charindex) {
        for (let wordOfDayCharIndex = 0; wordOfDayCharIndex < wordOfDay.length; ++wordOfDayCharIndex) {
          //console.log("LETRA: " + cleanUpSpecialChars(wordOfDay[wordOfDayCharIndex]) + "|"+ cleanUpSpecialChars(guesses[index].charAt(charindex)));
          //console.log(cleanUpSpecialChars(wordOfDay[wordOfDayCharIndex]) === cleanUpSpecialChars(guesses[index].charAt(charindex)));
          if (cleanUpSpecialChars(wordOfDay[wordOfDayCharIndex]) === cleanUpSpecialChars(guesses[index].charAt(charindex))) {
            isSolvedArray[wordOfDayCharIndex] = true;
          }
        }
      }
    }

  } else {
    for (let index = 0; index < guesses.length; ++index) {
      for (let charindex = 0; charindex < guesses[index].length; ++charindex) {
        //In case the current guess is bigger than the word of day, just break the inner loop.
        if (charindex > wordOfDay.length) {
          break;        
        }
        //console.log("FOR - WORD DAY REVELEAD");
        //console.log(cleanUpSpecialChars(wordOfDay.charAt(charindex)));
        //console.log(cleanUpSpecialChars(guesses[index].charAt(charindex)));
        if (cleanUpSpecialChars(wordOfDay.charAt(charindex)) === cleanUpSpecialChars(guesses[index].charAt(charindex))) {
          //onsole.log("E SIM");
          isSolvedArray[charindex] = true;
        }
      }
    }

  }

  //console.log("FINAL");
  //console.log(isSolvedArray);
  return (
    <div className="flex justify-center mb-1">
      {splitwordOfDay.map((letter, i) => (
        isSolvedArray[i] ? 
          <Cell
            key={i}
            isRevealing={true}
            isCompleted={true}
            value={letter}
            status="correct"
          />
        : 
          <Cell key={i} value=" " />
      ))}
    </div>
  )
  
}

export const WordOfDay = ({ rightLetters, className, guesses, isEasyMode }: Props) => {
  //console.log(guesses);
  const wordOfDay = getWordOfDay().solution;
  const tipOfDay = getWordOfDay().tip;
  //const splitGuess = unicodeSplit(guess)
  //const emptyCells = Array.from(Array(MAX_WORD_LENGTH - splitGuess.length))
  const classes = `flex justify-center text-sm mb-1 dark:text-white ${className}`
  const quoteClass = `flex justify-center text-center mx-auto text-base ml-2.0 font-bold dark:text-white ${className}`
  //var max_word_length_text = '';
  //var max_guesses = '';

  //if (MAX_WORD_LENGTH) {
  //  max_word_length_text = `Guesses must have a maximum of ${MAX_WORD_LENGTH} letters.`;
  //}

  //if (MAX_CHALLENGES) {
  //  max_guesses = `${guesses.length - 1} of ${MAX_CHALLENGES} tries`;
  //}

  return (
    <>
    <div className={quoteClass}>"{tipOfDay}"</div>
    <WordOfDayRevelead rightLetters={rightLetters} className={className} guesses={guesses} isEasyMode={isEasyMode}/>
    <div className={classes}>
      Palavra do dia possui {wordOfDay.length} letras (incluindo espaços).
    </div>
    </>
  )
}
