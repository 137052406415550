import { Cell } from './Cell'
import { CurrentTypingCell } from './CurrentTypingCell'
import { unicodeSplit } from '../../lib/words'


type Props = {
  guess: string
  className: string
}

export const CurrentRow = ({ guess, className }: Props) => {
  const splitGuess = unicodeSplit(guess)
  //const emptyCells = Array.from(Array(MAX_WORD_LENGTH - splitGuess.length))
  //const emptyCells = Array.from(Array(10))
  //console.log(emptyCells)
  const classes = `flex justify-center mb-1 ${className}`

  return (
    <div className={classes}>
      {splitGuess.map((letter, i) => (
        <Cell key={i} value={letter} />
      ))}
      <CurrentTypingCell />
    </div>
  )
}
