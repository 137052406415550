export const GAME_TITLE = process.env.REACT_APP_GAME_NAME!

export const WIN_MESSAGES = ['Ótimo trabalho!', 'Parabéns!', 'Muito bom!']
export const GAME_COPIED_MESSAGE = 'Jogo copiado'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Não há letras suficientes'
export const WORD_NOT_FOUND_MESSAGE = 'Palavra não encontrada'
export const HARD_MODE_ALERT_MESSAGE =
  'Modo difícil somente pode ser habilitado no começo!'
export const HARD_MODE_DESCRIPTION =
  'Qualquer letra revelada anteriormente precisa ser usada nos chutes subsequentes.'
export const EASY_MODE_DESCRIPTION =
  'Qualquer letra da palavra chutada que esteja na palavra do dia será revelada na posição correta.'
export const HIGH_CONTRAST_MODE_DESCRIPTION = 'Para melhorar a visualização'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `A palavra era ${solution}`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Precisa usar a letra ${guess} na posição ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `Chute precisa conter a legra ${letter}`
export const ENTER_TEXT = 'Enter'
export const DELETE_TEXT = 'Delete'
export const STATISTICS_TITLE = 'Estatísticas'
export const GUESS_DISTRIBUTION_TEXT = 'Distribuição dos chutes'
export const NEW_WORD_TEXT = 'Nova palavra em'
export const SHARE_TEXT = 'Compartilhar'
export const TOTAL_TRIES_TEXT = 'Total de Tentativas'
export const SUCCESS_RATE_TEXT = 'Taxa de sucesso'
export const CURRENT_STREAK_TEXT = 'Vitórias seguidas'
export const BEST_STREAK_TEXT = 'Melhor sequência'
export const SPACE_TEXT = "Space"
export const DISCOURAGE_INAPP_BROWSER_TEXT =
  "Você está usando um navegador embedded e pode enfrentar problemas para salvar ou compartilharr seus resultados. Recomenda-se utilizar o navegador padrão do seu dispositivo."
