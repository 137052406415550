export const WORDS = 
  [
    {"tip":"BOTÂNICA: As samambaias pertencem ao grupo das","word":"Pteridófitas"},
    {"tip":"BIOQUÍMICA: Elemento químico que atua na coagulação","word":"Cálcio"},
    {"tip":"ZOOLOGIA: Classificação dos cnidários quanto a presença de folhetos embrionários","word":"Diblásticos"},
    {"tip":"DOENÇAS: A sífilis é causada por uma","word":"Bactéria"},
    {"tip":"ECOLOGIA: Área de transição entre dois ecossistemas chamamos de","word":"Ecótono"},
    {"tip":"BOTÂNICA: A fase de vida predominante das briófitas é a de","word":"Gametófito"},
    {"tip":"BOTÂNICA: Primeiro grupo vetal a apresentar tecido de condução","word":"Pteridófitas"},
    {"tip":"CITOLOGIA: Substância relacionada a fluidez da membrana","word":"Colesterol"},
    {"tip":"BIOQUÍMICA: Sal mineral que compõe a clorofila","word":"magnésio"},
    {"tip":"HISTOLOGIA: O endotélio é um tecido epitelial de revestimento simples","word":"Pavimentoso"},
    {"tip":"EVOLUÇÃO: Criou a lei do uso e desuso","word":"Lamarck"},
    {"tip":"ZOOLOGIA: Célula das esponjas responsável pela captura do alimento","word":"Coanócitos"},
    {"tip":"HISTOLOGIA: Por serem bastante unidas, as células do tecido epitelial são chamadas de células","word":"Justapostas"},
    {"tip":"BIOQUÍMICA: A diferença de concentração entre Na e K é importante para o funcionamento do","word":"Neurônio"},
    {"tip":"BOTÂNICA: Gimnospermas e angiospermas são exemplos de plantas","word":"Fanerógamas"},
    {"tip":"CITOLOGIA: O modelo atual da membrana plasmática se chama Modelo do","word":"Mosaico fluido"},
    {"tip":"DOENÇAS: A covid 19 é causada pelo vírus","word":"Sars Cov 2"},
    {"tip":"EVOLUÇÃO: Darwin explicou a evolução com a teoria da ","word":"Seleção natural"},
    {"tip":"BOTÂNICA: A meiose nos vegetais originam os","word":"Esporos"},
    {"tip":"BOTÂNICA: Musgos são exemplos do grupo das","word":"Briófitas"},
    {"tip":"DOENÇAS: Agente etiológico da dengue é um","word":"Vírus"},
    {"tip":"BOTÂNICA: Grupos plantas que possuem flores com atrativos","word":"Angiospermas"},
    {"tip":"EMBRIOLOGIA: Denominamos a união do espermatozoide e do ovócito II de","word":"Fecundação"},
    {"tip":"HISTOLOGIA: A traqueia é constituída por um tecido epitelial","word":"pseudoestratificado"},
    {"tip":"HISTOLOGIA: A epiderme é classificada quanto ao número de camada como","word":"Estratificada"},
    {"tip":"FISIOLOGIA: pH ótimo próximo de 7 é encontrado na","word":"Boca"},
    {"tip":"ECOLOGIA: Denominamos o local mais provável de se encontrar um organismo de","word":"Habitat"},
    {"tip":"BOTÂNICA: Os gametas masculinos das briófitas denominam-se","word":"Anterozoide"},
    {"tip":"CITOLOGIA: Canais de transporte intermembranar são de natureza","word":"Proteica"},
    {"tip":"BOTÂNICA: Gimnospermas e angiospermas são exemplos de plantas","word":" Espermatófitas"},
    {"tip":"EVOLUÇÃO: O Neodarwinismo utiliza os conhecimentos da","word":"Genética"},
    {"tip":"FISIOLOGIA: Enzima que atua no estômago","word":"Pepsina"},
    {"tip":"EVOLUÇÃO: Seleção natural que favore um fenótipo que não era favorecido","word":"Direcional"},
    {"tip":"BIOQUÍMICA: Elemento químico que compõe os hormônios da tireoide","word":"Iodo"},
    {"tip":"ECOLOGIA: Biocenose + Biótopo =","word":"Ecossistema"},
    {"tip":"EMBRIOLOGIA: A ovogenese ocorre nos","word":"Ovários"},
    {"tip":"CITOLOGIA: Camada externa composta por carboidrato","word":"Glicocálice"},
    {"tip":"ZOOLOGIA: Células urticantes dos cnidários que capturam alimentos","word":"Cnidoblasto"},
    {"tip":"ZOOLOGIA: Os corais constituem o filo dos","word":"Cnidários"},
    {"tip":"EMBRIOLOGIA: A espermatogenese ocorre nos","word":"Testículos"},
    {"tip":"ZOOLOGIA: Filo do animais composto pelas esponjas","word":"Poríferos"},
    {"tip":"ECOLOGIA: Conjunto de indivíduos da mesma espécie","word":"População"},
    {"tip":"ECOLOGIA: Conjunto de indivíduos de espécies diferentes","word":"Comunidade"},
    {"tip":"CITOLOGIA: A membrana plasmática é composta predominantemente por","word":"Proteínas"},
    {"tip":"DOENÇAS: O linfócito T CD4 é infectado pelo vírus","word":"HIV"},
    {"tip":"FISIOLOGIA: pH ótimo próximo de 2 é encontrado no","word":"Estômago"},
    {"tip":"HISTOLOGIA: A bexiga é composta por um tecido epitelial de revestimento estratificado de","word":"transição"},
    {"tip":"FISIOLOGIA: pH ótimo próximo de 9 é encontrado no","word":"Intestino"},
    {"tip":"EVOLUÇÃO: Seleção natural que favorece os extremos fenotípicos","word":"Disruptiva"},
    {"tip":"EVOLUÇÃO: Explicou a evolução com a seleção natural","word":"Darwin"},
    {"tip":"BOTÂNICA: Grupo de plantas que possuem estróbilo","word":"Gimnospermas"},
    {"tip":"BOTÂNICA: A fase diploide dos vegetais denomina-se","word":"Esporófitos"},
    {"tip":"DOENÇAS: Hospedeiro intermediário da esquistossomose","word":"Caramujo"},
    {"tip":"EMBRIOLOGIA: Camada mais externa do Ovócito II","word":"Corona radiata"},
    {"tip":"BOTÂNICA: Primeiro grupo vetal a apresentar tubo polínico","word":"Gimnospermas"},
    {"tip":"FISIOLOGIA: Enzima que atua na boca é a","word":"Amilase salivar"},
    {"tip":"BOTÂNICA: A fase haploide dos vegetais denomina-se","word":"Gametófito"},
    {"tip":"BOTÂNICA: Estrutura definitiva para conquista do ambiente terrestre pelos vegetais","word":"Tubo polínico"},
    {"tip":"BIOQUÍMICA: Sal mineral que compõe a hemoglobina","word":"Ferro"},
    {"tip":"EVOLUÇÃO: Seleção natural que mantém um fenótipo já favorecido","word":"Estabilizadora"},
    {"tip":"BOTÂNICA: Primeiro grupo vetal a apresentar sementes","word":"Gimnospermas"},
    {"tip":"EMBRIOLOGIA: Organela que origina o acrossoma do espermatozoide","word":"Aparelho golgiense"}
  ]
